import Head from "next/head";
import LayoutComponent from "../../components/layout";
import Image from 'next/image';
import { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import Item from "../../components/ProductList/ProductItem";
import { getNewByKey, getNewestNew, getNewsGroup, getNewsGroupById } from "../../pages/api/blogs";
import environment from "../../utils/environment";
import reactStringReplace from 'react-string-replace';
import parse from 'html-react-parser';
import Link from "next/link";
import { IOrderType } from "../../@types/IProductList";
import PopupOrder from "../PopupOrder";
import PopupSignup from "../PopupSignup";
import { STORAGE_KEYS } from "../../utils/config";
import HeadComponent from "../layout/Head";
import AppContext from "../../store/AppContext";
import { useRouter } from "next/router";
import { addKeyToImage } from '../../utils/utils';
import { currencyFormatter } from "../../utils/format";
import { getProductList_keyword } from "../../pages/api/hello";

const BlogDetail = () => {
    const router = useRouter();
    const { branchs } = useContext(AppContext);
    const [origin, setOrigin] = useState("");
    const [openSignUp, setOpenSignUp] = useState(false);
    const [selectItems, setSelectItems] = useState<number>(0);
    const [rawData, setRawData]: any = useState(null);
    const [newestNews, setNewestNews]: any = useState(null);
    const [newsGroup, setNewsGroup]: any = useState(null);
    const heading = [/(?<=<h1>)(.*?)(?=<\/h1>)/g, /(?<=<h2>)(.*?)(?=<\/h2>)/g, /(?<=<h3>)(.*?)(?=<\/h3>)/g, /(?<=<h4>)(.*?)(?=<\/h4>)/g];
    const [isOpen, setIsOpen] = useState<{ isOpen: boolean, orderType: number, is_purchase: boolean | any, id: number | any, is_product: boolean }>({
        isOpen: false,
        orderType: 0,
        is_purchase: null,
        id: null,
        is_product: true
    });

    const [openNew, setOpenNew] = useState(true);
    const [openProduct, setOpenProduct] = useState(true);
    const [openDescription, setOpenDescription] = useState(true);
    const [categoryGroup, setCategoryGroup] = useState<any>([]);
    const [openProdRelated, setOpenProdRelated] = useState(true);

    const [relatedProduct, setRelatedProduct]: any = useState(null);
    const settings = {
        dots: false,
        infinite: false,
        speed: 800,
        autoplay: false,
        autoplaySpeed: 4000,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    async function getRawData() {
        try {
            if (router.asPath) {
                let res: any = await getNewByKey(router.asPath.slice(1, router.asPath.length));

                if (res) {
                    await setRawData(res);
                    let categoryGroups_: any = [];
                    for (let index = 0; index < res?.category_group.length; index++) {
                        let a = await callCategoryGroup(res.id, res?.category_group[index]);
                        categoryGroups_ = [...categoryGroups_, a];
                    }
                    await setCategoryGroup(categoryGroups_);

                    if(res.seo?.keyword?.length > 0){
                        let arr_kc = res.seo?.keyword.map((x:any) => x.code);
                        console.log(arr_kc);
                        loadRelatedProduct(arr_kc.toString());
                    }
                    
                }
            }
        } catch (error) {

        }
    }

    async function loadNewestNews() {
        try {
            let newestNews = await getNewestNew();
            setNewestNews(newestNews);
        } catch (error) {

        }
    }

    async function loadNewestGroup() {
        try {
            let newsGroup = await getNewsGroup();
            setNewsGroup(newsGroup);
        } catch (error) {

        }
    }

    function openSellPopup(id: number, is_product: boolean) {
        setIsOpen({
            ...isOpen,
            isOpen: true,
            orderType: IOrderType.sell,
            is_purchase: true,
            id: id,
            is_product: is_product
        })
    }

    function setIdOrderTrialPopup(id: number, is_product: boolean) {
        setIsOpen({
            ...isOpen,
            isOpen: false,
            orderType: IOrderType.orderCalendar,
            id: id,
            is_product: is_product
        })
    }

    function openRentPopup(id: number, is_product: boolean) {
        setIsOpen({
            ...isOpen,
            isOpen: true,
            orderType: IOrderType.rent,
            is_purchase: false,
            id: id,
            is_product: is_product
        })
    }

    function openOrderTrialPopup(id: number, is_product: boolean) {
        setIsOpen({
            ...isOpen,
            isOpen: true,
            orderType: IOrderType.orderCalendar,
            id: id,
            is_product: is_product
        })
    }

    function closePopup() {
        setIsOpen({
            ...isOpen,
            isOpen: false,
            is_purchase: null,
        })
    }

    function getSuggest() {
        let array: any = [];
        for (let i = 0; i < heading.length; i++) {
            const reg = (rawData?.content ?? "").match(heading[i]);

            reg ? array = [
                ...array,
                ...reg
            ] : null;

        }

        return array;
    }

    const BlogMenu = () => {
        const [active, setActive] = useState(true);

        function onOpen() {
            setActive(!active);
        }

        return <div className="blog_detail--menu">
            <div onClick={onOpen} className="flex flex-row justify-between gap-2 items-center">
                <h3>Mục lục nội dung</h3>
                <Image
                    src={"/asset/icon/ico_menu.svg"}
                    className={!active ? "menu--icon--active" : ""}
                    alt={"menu"}
                    width={24}
                    height={24}
                />
            </div>
            {
                active && <div className="flex flex-row gap-3 items-center">
                    <MenuSuggest key={"1"} />
                </div>
            }
        </div>
    }

    const MenuSuggest = () => {
        return <div className="flex flex-col">
            {getSuggest().map((item: string, index: number) => {
                return <div key={index} className="flex flex-row items-center gap-3">
                    <Image
                        src={"/asset/icon/ico_arrow_blog_menu.svg"}
                        alt={"arrow-blog-menu"}
                        width={24}
                        height={24}
                    />
                    <div className="items-center flex" dangerouslySetInnerHTML={{ __html: item }}></div>
                </div>
            })}
        </div>
    }

    function onHandleSelectItem(id: number) {
        setSelectItems(id);
    }

    function additionalFunc() {
        let name = localStorage.getItem(STORAGE_KEYS.name);
        let email = localStorage.getItem(STORAGE_KEYS.email);
        let phone = localStorage.getItem(STORAGE_KEYS.phone);
        if (!name || !phone) {

            setOpenSignUp(true);
            return false
        } else {
            openOrderTrialPopup(isOpen.id, isOpen.is_product)
        }
        return true
    }

    async function callCategoryGroup(id: number, keyword: string) {
        let res: any = await getNewsGroupById(id, keyword);
        if (res) {
            return res?.results ?? []
        }
    }
    //console.log("rawData", rawData);

    //@ts-ignore
    const OnRender = ({ rawData, groups }: any) => {

        let resultCase: any = reactStringReplace(rawData?.content, 'weqweqw', (match, i) => (<></>));
        for (let index = 0; index < rawData?.category_group?.length; index++) {


            if (index === 0) {
                resultCase = reactStringReplace(resultCase, `<div id="${rawData?.category_group[index]}" style="pointer-events:none;width:100%;height:150px;border:1px solid #e8e8e8;display:flex;justify-content:center;align-items:center">Danh mục sản phẩm</div>`, (match, i) => (
                    <div className="w-full lg:block h-full">

                        <Slider {...settings}>
                            {
                                groups?.length > 0 && groups[index]?.map((item: any) => {
                                    return (
                                        <div key={item.id} className="px-2 wrapItemProductBlogDetail">
                                            <Item
                                                //height={" h-[350px] lg:h-[318px] xl:h-[450px]"}
                                                objectFit="cover"
                                                setOpenSignUp={setOpenSignUp}
                                                setIdOrderTrialPopup={setIdOrderTrialPopup}
                                                openSellPopup={openSellPopup}
                                                openRentPopup={openRentPopup}
                                                openOrderTrialPopup={openOrderTrialPopup}
                                                item={item} key={item.id}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                ));
            } else {
                resultCase = reactStringReplace(resultCase, `<div id="${rawData?.category_group[index]}" style="pointer-events:none;width:100%;height:150px;border:1px solid #e8e8e8;display:flex;justify-content:center;align-items:center">Danh mục sản phẩm</div>`, (match, i) => (
                    <div className="w-full lg:block h-full">
                        <Slider {...settings}>
                            {
                                groups?.length > 0 && groups[index]?.map((item: any) => {
                                    return (
                                        <div key={item.id} className="px-2 wrapItemProductBlogDetail">
                                            <Item
                                                //height={" h-[350px] lg:h-[318px] xl:h-[450px]"}
                                                objectFit="cover"
                                                setOpenSignUp={setOpenSignUp}
                                                setIdOrderTrialPopup={setIdOrderTrialPopup}
                                                openSellPopup={openSellPopup}
                                                openRentPopup={openRentPopup}
                                                openOrderTrialPopup={openOrderTrialPopup}
                                                item={item} key={item.id}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                ));
            }

        }



        return (
            <>
                {resultCase?.map((item: any, index: number) => {
                    if (typeof item === "string") {
                        return <div key={index + "_case_1"}>{parse(item)}</div>;
                    }
                    return <div key={index + "_case_1"}>{item}</div>;
                })}
            </>
        );
    }
    const [isLoading, setIsLoading] = useState(true);

    async function loadRelatedProduct(keywordCode:any) {
        // await getProductList_keyword(`search=${search}&limit=${limit}&page=${page}`);
        let res: any = await getProductList_keyword(`keyword_codes=${keywordCode}&limit=${5}&page=${1}`);
        if (res && res.results.length > 0) {
            setRelatedProduct(res.results);
        }
    }

    useEffect(() => {
        getRawData();
        loadNewestNews();
        loadNewestGroup();
        setOrigin(window?.location?.origin);
        
        setIsLoading(false);
    }, [])

    // useEffect(() => {
    //     //getRawData();
    // }, [blog])

    return <>
        {/* <Head>
            <title>{rawData?.name}</title>
            <meta name="description" content="Generated by create next app" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="icon" href="/favicon.ico" />
            <link rel="canonical" href={origin} />
        </Head> */}
        <HeadComponent />
        <LayoutComponent className='' isLoading={isLoading}>
            <div className="banner-blog">
                <div className="banner-backgroud">
                    <Image
                        className="w-full"
                        src={"/asset/image/blog-banner.png"}
                        alt={"blog banner"}
                        layout='fill'
                        objectFit='cover'
                    />
                </div>
                <div className="banner-content flex flex-col gap-1 justify-center px-4">
                    <span className="text-base">{rawData?.categories?.name}</span>
                    <div className="flex justify-center">
                        <p className="last-title text-2xl lg:text-[2rem] lg:leading-[2.5rem]">{rawData?.name}</p>
                    </div>
                </div>
            </div>
            <div className="blog_layout flex flex-row gap-6 w-full py-2 px-2 md:px-6  lg:py-6 xl:px-20 overflow-hidden">
                {/* <div className="blog_detail w-full lg:w-2/3 flex-col">
                    <BlogMenu key={"menu"} />
                    {onRender(rawData)}
                </div> */}
                <div id='form_in_modal_group_content' className='flex-1 w-full lg:w-2/3 richTextEditor e-control e-richtexteditor e-lib e-rte-toolbar-enabled'>
                    <div className='e-rte-content h-auto mt-0' id="form_in_modal_group_contentrte_view">
                        <div id="form_in_modal_group_content_rte-edit-view" tabIndex={0} className="p-[1rem] lg:p-[2.5rem] blog_detail flex-col e-lib e-keyboard">
                            <BlogMenu key={"menu"} />
                            <OnRender rawData={rawData} groups={categoryGroup} />
                            {/* {onRender(rawData, categoryGroup)} */}
                        </div>
                    </div>
                </div>
                <div className="blog_filter hidden lg:flex lg:flex-col">
                    {
                        rawData?.description && <div className="flex flex-col">
                        <div className="flex flex-col w-full mb-8">
                            <div onClick={() => { setOpenDescription(!openDescription) }} className="flex flex-row justify-between gap-1 items-center w-full cursor-pointer">
                                <span className="sub_menu_heading">
                                    Giới thiệu
                                </span>
                                {openDescription ? <Image
                                    src={"/asset/icon/ico_minus.svg"}
                                    alt={"arrow-minus"}
                                    width={24}
                                    height={24}
                                /> : <div className="plus alt" />}
                            </div>
                            {openDescription && <div className="sub_menu_line"></div>}
                        </div>
                        <div
                            className={`
                                ${openDescription === true ? "wrapContent--active" : "wrapContent"}
                            `}
                        >
                            <p className="mb-8">{rawData?.description ?? ""}</p>
                        </div>
                    </div>
                    }
                    
                    <div className="flex flex-col">
                        <div className="flex flex-col w-full mb-8">
                            <div onClick={() => { setOpenProduct(!openProduct) }} className="flex flex-row justify-between gap-1 items-center w-full cursor-pointer">
                                <span className="sub_menu_heading">
                                    Danh mục sản phẩm
                                </span>
                                {openProduct ? <Image
                                    src={"/asset/icon/ico_minus.svg"}
                                    alt={"arrow-minus"}
                                    width={24}
                                    height={24}
                                /> : <div className="plus alt" />}
                            </div>
                            {openProduct && <div className="sub_menu_line"></div>}
                        </div>
                        <div
                            className={`
                                ${openProduct === true ? "wrapContent--active" : "wrapContent"}
                            `}
                        >
                            <div className="filter_layout--categories uppercase mb-8">
                                <span>Tất cả sản phẩm</span>
                                {
                                    newsGroup?.map((item: any) => {
                                        return <MenuParent selectItems={selectItems} onHandleSelectItem={onHandleSelectItem} key={item.id} item={item} />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex flex-col w-full mb-8">
                            <div onClick={() => { setOpenNew(!openNew) }} className="flex flex-row justify-between gap-1 items-center w-full cursor-pointer">
                                <span className="sub_menu_heading">
                                    Tin mới nhất
                                </span>
                                {openNew ? <Image
                                    src={"/asset/icon/ico_minus.svg"}
                                    alt={"arrow-minus"}
                                    width={24}
                                    height={24}
                                /> : <div className="plus alt" />}
                            </div>
                            {openNew && <div className="sub_menu_line"></div>}
                        </div>
                        <div
                            className={`
                                ${openNew === true ? "wrapContent--active" : "wrapContent"}
                            `}
                        >
                            <div className="flex flex-col gap-4">
                                {
                                    newestNews?.map((item: any) => {
                                        return (
                                            <Link key={item.id} rel="noopener noreferrer" className="flex flex-row gap-2"
                                                href={{ pathname: '/[...group]', query: { group: item?.url?.split("/").filter((e: any) => e !== '') } }}
                                            //href={item.url}
                                            >
                                                <div className="wrapImageActicle">
                                                    <Image
                                                        src={item.image_url ? environment.baseImageUrl + item.image_url : "/asset/image/No_Image_Available.webp"}
                                                        alt={item?.alt}
                                                        title={item?.alt}
                                                        className={"newest_new_image"}
                                                        layout='fill'
                                                        objectFit={"cover"}
                                                    />
                                                </div>
                                                <div className="wrapContentActicle flex flex-col gap-2">
                                                    <p className="newest_news_head">{item?.name ?? ""}</p>
                                                    <p className="newest_news_content">Chức năng bình luận bị tắt</p>
                                                </div>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    {
                        relatedProduct && <div className="flex flex-col mt-8">
                        <div className="flex flex-col w-full mb-8">
                            <div onClick={() => { setOpenProdRelated(!openProdRelated) }} className="flex flex-row justify-between gap-1 items-center w-full cursor-pointer">
                                <span className="sub_menu_heading">
                                    Sản phẩm bạn quan tâm
                                </span>
                                {openProdRelated ? <Image
                                    src={"/asset/icon/ico_minus.svg"}
                                    alt={"arrow-minus"}
                                    width={24}
                                    height={24}
                                /> : <div className="plus alt" />}
                            </div>
                            {openProdRelated && <div className="sub_menu_line"></div>}
                        </div>
                        <div
                            className={`
                                ${openProdRelated === true ? "wrapContent--active" : "wrapContent"}
                            `}
                        >
                            <div className="flex flex-col gap-4">
                                {
                                    relatedProduct?.map((item: any) => {
                                        let alt_def = item?.file?.alt ? item?.file?.alt : "HGS";
                                        return (
                                            <Link key={item.id} rel="noopener noreferrer" className="flex flex-row gap-2"
                                                href={{ pathname: '/[...group]', query: { group: item?.url?.split("/").filter((e: any) => e !== '') } }}
                                            //href={item.url}
                                            >
                                                <div className="wrapImageActicle">
                                                    <Image
                                                        src={item.file ? environment.baseImageUrl + addKeyToImage(item.file.image_url, 0, false, true, false, false) : "/asset/image/No_Image_Available.webp"}
                                                        alt={alt_def}
                                                        title={alt_def}
                                                        className={"newest_new_image"}
                                                        layout='fill'
                                                        objectFit={"cover"}
                                                    />
                                                </div>
                                                <div className="wrapContentActicle flex flex-col gap-2">
                                                    <div className="newest_news_head break_2">{item?.name ?? ""}</div>
                                                    <div className="flex flex-row gap-1 md:gap-2 justify-between">
                                                        {
                                                            item.allowed_sell === true && item?.rent_price ?  <span className={"flex flex-col content inline text-xs md:text-sm text-[#434343] "}>
                                                                                    Giá thuê
                                                                                    <span className='inline content text-xs md:text-sm text-[#FF4D4F]'>
                                                                                        {item?.rent_price ? currencyFormatter.format(item?.rent_price) : ""}
                                                                                    </span>
                                                                                </span>
                                                                            : null
                                                        }
                                                        {
                                                            item?.purchase_price ? <span className={"flex flex-col content inline text-xs md:text-sm text-[#434343] "}>
                                                                                    Giá bán
                                                                                    <span className='inline content text-xs md:text-sm text-[#FF4D4F]'>
                                                                                        {item?.purchase_price ? currencyFormatter.format(item?.purchase_price) : ""}
                                                                                    </span>
                                                                                </span>
                                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    }
                    
                </div>
            </div>
            {isOpen.isOpen && <PopupOrder
                is_purchase={isOpen.is_purchase}
                isOpen={isOpen.isOpen}
                setIsOpen={closePopup}
                id={isOpen?.id}
                orderType={isOpen.orderType}
                branchs={branchs}
                is_product={isOpen.is_product}
                orderDate={{
                    takeDate: null,
                    returnDate: null
                }} isOthers={false} />}
            {openSignUp && <PopupSignup id={isOpen.id} setOpen={setOpenSignUp} additionalFunc={additionalFunc} is_product={isOpen.is_product} />}
        </LayoutComponent>
    </>
}

const MenuParent = ({ item = null, index = 0, isChild = false, selectItems, onHandleSelectItem }: any) => {
    index += 1;

    const [isOpen, setIsOpen] = useState(checkIsOpen());
    function checkIsOpen() {
        let isOpen = false;
        if (selectItems == item.id) {
            isOpen = true;
        }
        item.children?.map((item: any) => {
            if (selectItems == item.id) {
                isOpen = true;
            }
            return item;
        });
        return isOpen;
    }
    function handleOpenClose() {
        setIsOpen(!isOpen);
    }

    // let props: any = {};
    // if (item?.url?.replace(/^\/+/, '')) {
    //     props.href = item.url
    // }
    return <div className={isChild ? 'menu_parent--child' : ""}>
        <div className={'cursor-pointer text-left flex justify-between ' + (isOpen && isChild === false ? "active flex-row" : isChild && isOpen ? "menu_parent--child--line--active flex-row-reverse" : isChild === true ? "menu_parent--child--line flex-row-reverse" : " flex-row")}
        >
            <Link target="_blank" rel="noopener noreferrer"
                //{...props}
                href={{ pathname: '/[...group]', query: { group: item?.url?.split("/").filter((e: any) => e !== '') } }}
            >
                {item?.name ?? ""}
            </Link>
            <div className="" onClick={() => {
                if (item?.children && item.children?.length > 0) {
                    handleOpenClose()
                }
                if (!isOpen) {
                    onHandleSelectItem(item.id)
                }
            }}>
                {item?.children && item.children?.length > 0 ? !isOpen ? <Image
                    src="/asset/icon/ico_vector_down_menu.svg"
                    alt="open menu"
                    className="rotate-[90deg]"
                    width={24}
                    height={24}
                /> : <Image
                    src="/asset/icon/ico_vector_up_menu.svg"
                    alt="close menu"
                    width={24}
                    height={24}
                /> : <></>}
            </div>
        </div>
        {isOpen && <MenuChildZone selectItems={selectItems} onHandleSelectItem={onHandleSelectItem} item={item} index={index} />}

    </div>
}
const MenuChildZone = ({ item, index, onHandleSelectItem, selectItems }: any) => {
    let padding = index ? index * 12 : 0;

    return <div className={'filter_layout--categories--child_zone filter_layout--line'} style={{ paddingLeft: padding + "px" }}>
        {
            item?.children?.map((item: any) => {
                return <MenuChild selectItems={selectItems} onHandleSelectItem={onHandleSelectItem} index={index} key={item.id} item={item} />
            })
        }
    </div>
}
const MenuChild = ({ item, index, onHandleSelectItem, selectItems }: any) => {
    let props: any = {};
    if (item?.url?.replace(/^\/+/, '')) {
        props.href = item.url
    }
    return <>{item?.children && item.children.length > 0 ? <MenuParent onHandleSelectItem={onHandleSelectItem} index={index} key={item.id} item={item} isChild={true} /> : <Link
        target="_blank" rel="noopener noreferrer"
        //{...props}
        href={{ pathname: '/[...group]', query: { group: item?.url?.split("/").filter((e: any) => e !== '') } }}
        onClick={() => onHandleSelectItem(item.id)}
        className={'cursor-pointer text-left ' + (selectItems == item.id ? "active" : "")}>
        {item?.name ?? ""}
    </Link>}</>
}

export default BlogDetail;

import BlogDetail from "../../components/BlogDetail";
import { useContext } from "react";
// import Product from "./san-pham/back-up-[...product]";
// import Policy from "../chinh-sach/[policy]";
// import Product from "../(san-pham)/[...slug]";
import { useRouter } from "next/router";
import React from "react";
import dynamic from 'next/dynamic'
import LoadingComponent from "../../components/Loading";
import AppContext from "../../store/AppContext";
import NotFound from "../not-found";

const DynamicProducts = dynamic(() => import('../../components/ProductList'));
const DynamicSeachPage = dynamic(() => import('../tu-khoa/[...slug]'));
const DynamicBlogs = dynamic(() => import('../../components/BlogList'));
const DynamicPolicy = dynamic(() => import('../chinh-sach/[policy]'), { ssr: false })
const DynamicProduct = dynamic(() => import('../(san-pham)/[...slug]'));

const ConfigDynamicType = {
    0: "Danh sách sản phẩm",
    1: "Chi tiết sản phẩm",
    2: "Danh sách tin tức",
    3: "Chi tiết tin tức"
}

// export function arrayProductData(array: Array<any>) {
//     let cloneArrayTypeProduct: any = array ?? [];
//     for (let index = 0; index < array?.length; index++) {
//         const children = array[index]?.children ?? [];
//         let child = [];
//         if (children.length > 0) {
//             child = arrayProductData(children);
//             cloneArrayTypeProduct = cloneArrayTypeProduct.concat(child);
//         }
//     }
//     return cloneArrayTypeProduct;
// }

export function getServerSideProps({ req, res }: any) {
    res.setHeader(
        'Cache-Control',
        'public, s-maxage=10, stale-while-revalidate=59'
    )
    return {
        props: {

        },
    };
}

// { params, data, type }: any
const DynamicRoute = () => {
    const { type }: any = useContext(AppContext);

    return <><Render type={type} /></>
}

const Render = ({ isReload, type }: any) => {

    const router = useRouter();

    let url = router.query.slug ? "/" + (router.query.slug as Array<string>)?.join("/") : "";

    if (url === "/chinh-sach/quy-dinh-thue") {
        return <DynamicPolicy />
    } else if (url === "/chinh-sach/goi-thue") {
        return <DynamicPolicy />
    } else if (url.indexOf("tu-khoa") > -1) {
        return <DynamicSeachPage />
    }

    switch (type) {
        case 0:
            return <div>
                <DynamicProducts
                    groupTypeProductsData={[]}
                    key={"product"}
                    branchDatas={[]}
                    typeProductsData={[]}
                    filterSetData={[]}
                    productGroupsData={[]}
                    productsData={[]}
                    activeTypeData={""} />
            </div>
        case 1:
            return <DynamicProduct />
            break;
        case 2:
            return <div><DynamicBlogs /></div>
        case 3:
            return <div><BlogDetail /></div>
        case 4:
            return <DynamicPolicy />
            break;
        case 5:
            return <DynamicPolicy />
            break;
        case 6:
            return <DynamicSeachPage />
            break;
        case -1:
            return <LoadingComponent loading={true} />
            break;
        default:
            return <NotFound />
            // return <LoadingComponent loading={true} />
            break;
    }

}

export default DynamicRoute;
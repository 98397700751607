import Link from 'next/link'
import Head from "next/head";
import dynamic from 'next/dynamic';
const DynamicHeadComponent = dynamic(() => import('../components/layout/Head'));
import LayoutComponent from "../components/layout";
import ButtonPrimary from "../components/Button/ButtonPrimary";

const NotFound = () => {
  return (
        <>
            <DynamicHeadComponent />
            <LayoutComponent
                props={{
                    id: "main"
                }}
                isLoading={false}>
                <div className="flex flex-col items-center justify-center">
                    <img src="/asset/image/bg_not_found.svg"/>
                    <div className="flex flex-col items-center gap-3 mb-6">
                        <h2 style={{
                            fontWeight: "bold",
                            fontSize: "2rem"
                        }}>
                            Không tìm thấy trang
                        </h2>
                        <p style={{color: "#6B7280"}}>
                            Oops! Looks like you followed a bad link. If you think this is a problem with us, please tell us.
                        </p>
                        <ButtonPrimary className="cart_btn-footer-payment" onClick={()=>{
                            window.location.href = "/";
                        }}>Trở lại trang chủ</ButtonPrimary>
                    </div>
                    
                </div>
            </LayoutComponent>
        </>
    )
}
export default NotFound;

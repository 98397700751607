import BaseButton from "./BaseButton";

const ButtonPrimary = ({ className = '', style = {}, ...props }) => {

    return (
        <BaseButton className={ `btn-primary ${ className }` } style={style} {...props}>
            { props.children }
        </BaseButton>
    )
}

export default ButtonPrimary

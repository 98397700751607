import apiModule from "../../utils/api";
import { baseURL } from "./hello";

export const getNews = async (id: string) => {
    let response = await apiModule.get(baseURL + `/news/landing-page?page_url=${id}`);
    if (response) {
        return response.data;
    }
}
export const getNewByKey = async (key: any) => {
    let response = await apiModule.get(baseURL + `/landing-page/get-news-content-by-key?key=${key}`);
    if (response) {
        return response.data;
    }
}

export const getNewestNew = async () => {
    let response = await apiModule.get(baseURL + `/landing-page/news/new`);

    if (response) {
        return response.data;
    }
}

export const getNewsGroup = async () => {
    let response = await apiModule.get(baseURL + `/landing-page/news/product-group`);

    if (response) {
        return response.data;
    }
}

export const getNewsGroupById = async (id: number, keyword: string) => {
    let response = await apiModule.get(baseURL + `/news/${id}/groups?id=${id}&keyword=${keyword}&page=1&limit=20`);

    if (response) {
        return response.data;
    }
}


export const getNewKeys = async () => {
    let response = await apiModule.get(baseURL + `/landing-page/news/keys`);
    if (response) {
        return response.data;
    }
}

export const getNewPages = async () => {
    let response = await apiModule.get(baseURL + `/landing-page/news/pages`);
    if (response) {
        return response.data;
    }
}

export const getNewPagesV2 = async (page_url: string) => {
    let response = await apiModule.get(baseURL + `/landing-page/news?page_url=${page_url}`);
    if (response) {
        return response.data;
    }
}

export const getNewCategoryV2 = async (params: string) => {
    let response = await apiModule.get(baseURL + `/landing-page/news/category?${params}`);
    if (response) {
        return response.data;
    }
}


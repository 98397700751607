const BaseButton = ({ className = '', style = {}, ...props }) => {

    return (
        <button className={ `btn ${className}` } style={style} {...props}>
            { props.children }
        </button>
    )
}

export default BaseButton
